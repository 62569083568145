import React, { useRef, useEffect } from "react"
import katex from "katex"

const Katex = ({ className, children, displayMode = false }) => {
  const ref = useRef(null)

  useEffect(() => {
    katex.render(children, ref.current, {
      throwOnError: false,
      displayMode,
    })
  })

  return <span className={className} ref={ref} />
}

export const InlineEquation = ({ children }) => (
  <Katex className="inline-equation">{children}</Katex>
)

export const BlockEquation = ({ children, main = false }) => (
  <div
    className={`pa3 mv4 ba overflow-auto ${
      main ? "bw1 b--primary" : "b--border"
    }`}
  >
    <Katex displayMode>{children}</Katex>
  </div>
)
