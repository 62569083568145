import React from "react"
import {
  SW1,
  SW2,
  PRIMARY,
  BORDER,
  ERROR,
  HIGHLIGHT,
} from "drawing-components/styles"
import { Text, Arrow } from "drawing-components/svg-components"

export const Columns = ({ columns }) => {
  const style = {
    vectorEffect: "non-scaling-stroke",
    strokeWidth: SW1,
    stroke: BORDER,
    fill: BORDER,
    fillOpacity: 0.6,
  }

  return columns.map(({ x, y, height, width }, index) => {
    return (
      <rect
        key={index}
        style={style}
        x={x - width / 2}
        y={-y - height / 2}
        width={width}
        height={height}
      />
    )
  })
}

export const Pile = ({ pile, style }) => (
  <circle style={{ ...style }} cx={pile.x} cy={-pile.y} r={pile.diameter / 2} />
)

export const PilesLabels = ({ piles, scale }) => {
  return (
    <g>
      {piles.map((pile, index) => (
        <Text
          key={index}
          x={pile.x}
          y={-pile.y}
          dominantBaseline="top"
          scale={scale}
        >{`E${index + 1}`}</Text>
      ))}
    </g>
  )
}

export const PilesReactions = ({ piles, reactions, pilecapWeight, scale }) => {
  const numberOfPiles = piles.length
  const reactionDueToWeight =
    numberOfPiles > 0 ? pilecapWeight / numberOfPiles : 0

  const showReactionDueToWeight = reactionDueToWeight > 0

  return (
    <g>
      {piles.map((pile, index) => {
        const reaction = reactions[index] || 0
        const totalReaction = reaction + reactionDueToWeight

        const reactionLabel =
          reaction === Infinity ? "\u221E" : reaction.toFixed(0)
        const reactionDueToWeightLabel = reactionDueToWeight.toFixed(0)
        const totalLabel =
          totalReaction === Infinity ? "\u221E" : totalReaction.toFixed(0)

        if (showReactionDueToWeight) {
          const topLabel = `${reactionLabel}+${reactionDueToWeightLabel}`
          const bottomLabel = totalLabel
          return (
            <g key={index}>
              <Text
                style={{ fontSize: 11, fillOpacity: 0.4 }}
                x={pile.x}
                y={-pile.y}
                dy={-11}
                dominantBaseline="top"
                scale={scale}
              >
                {topLabel}
              </Text>
              <Text
                x={pile.x}
                y={-pile.y}
                // dominantBaseline="hanging"
                scale={scale}
              >
                {bottomLabel}
              </Text>
            </g>
          )
        } else {
          return (
            <Text
              key={index}
              x={pile.x}
              y={-pile.y}
              // dominantBaseline="hanging"
              scale={scale}
            >
              {reactionLabel}
            </Text>
          )
        }
      })}
    </g>
  )
}

export const Piles = ({ piles, reactions, pilecapWeight, pileCapacity }) => {
  const style = {
    strokeWidth: SW2,
    stroke: PRIMARY,
    vectorEffect: "non-scaling-stroke",
    transition: "fill 0.5s",
  }

  const numberOfPiles = piles.length
  const reactionDueToWeight =
    numberOfPiles > 0 ? pilecapWeight / numberOfPiles : 0

  return (
    <g>
      {piles.map((pile, index) => {
        const reaction = reactions[index] || 0
        const totalReaction = reaction + reactionDueToWeight
        const isSafe =
          pileCapacity === null ||
          (totalReaction <= pileCapacity && totalReaction >= 0)
        return (
          <Pile
            key={index}
            pile={pile}
            style={{
              ...style,
              stroke: isSafe ? PRIMARY : ERROR,
              fill: isSafe ? "transparent" : ERROR,
            }}
          />
        )
      })}
    </g>
  )
}

export const AddPileCursor = ({ pile }) => {
  const style = {
    strokeWidth: SW2,
    stroke: PRIMARY,
    vectorEffect: "non-scaling-stroke",
    fill: "transparent",
  }
  return <Pile pile={pile} style={style} />
}

export const RemovePileCursor = ({ pile }) => {
  const style = {
    strokeWidth: SW2,
    stroke: BORDER,
    vectorEffect: "non-scaling-stroke",
    fill: "transparent",
    strokeDasharray: "5",
  }
  return <Pile pile={pile} style={style} />
}

export const Edges = ({ vertices, dashed = false }) => {
  const style = {
    vectorEffect: "non-scaling-stroke",
    strokeWidth: dashed ? 0 : SW1,
    stroke: BORDER,
    strokeOpacity: 1,
    fill: BORDER,
    fillOpacity: 0.4,
    strokeDasharray: dashed && "2",
  }

  const points = vertices.map(({ x, y }) => [x, -y])
  return <polygon className="fade-in-animation" style={style} points={points} />
}

export const Struts = ({ struts, scale }) => {
  return struts.map((strut, index) => (
    <Strut key={index} strut={strut} scale={scale} />
  ))
}

const Strut = ({ strut, scale }) => {
  return (
    <Arrow
      style={{ strokeWidth: SW2 }}
      x1={strut[0].x}
      x2={strut[1].x}
      y1={-strut[0].y}
      y2={-strut[1].y}
      scale={scale}
    />
  )
}

export const Isoareas = ({ isoareas }) => {
  return isoareas.map((isoarea, index) => (
    <Isoarea key={index} isoarea={isoarea} />
  ))
}

const Isoarea = ({ isoarea }) => {
  const style = {
    vectorEffect: "non-scaling-stroke",
    strokeWidth: SW2,
    stroke: PRIMARY,
    strokeOpacity: 1,
    fill: "transparent",
  }

  const { x, y, width, height } = isoarea

  return (
    <rect
      style={style}
      x={x - width / 2}
      y={-y - height / 2}
      width={width}
      height={height}
    />
  )
}
