import React, { useState, useRef } from "react"

const Input = ({ id, label, value, onChange, min = "", max = "", ...rest }) => {
  const ref = useRef(null)
  const [inputValue, setInputValue] = useState(value)
  const [isValid, setIsValid] = useState(true)

  const handleOnChange = event => {
    setInputValue(event.target.value)

    let { value } = event.target

    if (min !== "" && value < min) {
      setIsValid(false)
      return null
    }

    if (max !== "" && value > max) {
      setIsValid(false)
      return null
    }

    setIsValid(true)
    return onChange(event)
  }

  return (
    <div className="relative">
      <label
        className="pointer c-secondary nowrap pl2"
        htmlFor={id}
        onClick={() => ref.current.select()}
      >
        {label}
      </label>
      <input
        ref={ref}
        className={`w-100 h-tap pl2 bn outline-0 b bg-foreground ba b--border mt2 ${
          !isValid && "outline-error c-error"
        }`}
        id={id}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="off"
        value={inputValue}
        onChange={handleOnChange}
        {...rest}
      />
      {!isValid && (
        <div
          className="w-100 pv0 absolute bottom-0 z-max fade-in-animation"
          style={{ transform: "translateY(100%)" }}
        >
          <div
            className="w-100 ph2 f1 c-error"
            style={{ lineHeight: "2rem" }}
          >{`mínimo = ${min}`}</div>
        </div>
      )}
    </div>
  )
}

export default Input
