import { sortPoints } from "./utils"

export const convexHull = points => {
  // Computes the convex hull of a set of 2D points.
  // Input: an iterable sequence of (x, y) pairs representing the points [[x, y], ...].
  // Output: a list of vertices of the convex hull in counter-clockwise order,
  // starting from the vertex with the lexicographically smallest coordinates.
  // Implements Andrew's monotone chain algorithm. O(n log n) complexity.

  if (points.length <= 1) {
    return points
  }

  const sorted = sortPoints(points)

  const lowerHullPoints = getHullPoints(sorted)
  const upperHullPoints = getHullPoints([...sorted].reverse())

  // avoid duplicaiton by removing last point
  return [...lowerHullPoints.slice(0, -1), ...upperHullPoints.slice(0, -1)]
}

const cross = (origin, pa, pb) => {
  // 2D cross product of OA and OB vectors, i.e. z-component of their 3D cross product.
  // Returns a positive value, if OAB makes a counter-clockwise turn,
  // negative for clockwise turn, and zero if the points are collinear.
  return (
    (pa.x - origin.x) * (pb.y - origin.y) -
    (pa.y - origin.y) * (pb.x - origin.x)
  )
}

const getHullPoints = points => {
  let hullPoints = []

  for (const point of points) {
    while (
      hullPoints.length >= 2 &&
      cross(
        hullPoints[hullPoints.length - 2],
        hullPoints[hullPoints.length - 1],
        point
      ) <= 0
    ) {
      hullPoints.pop()
    }
    hullPoints.push(point)
  }

  return hullPoints
}
