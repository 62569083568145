import React, { useState, useEffect, useRef } from "react"
import {
  getHullVertices,
  getRoundedHullVertices,
} from "lib/pilecap/hull-vertices"
import { getPilesReactions } from "lib/pilecap/reactions"
import PilecapTopView from "./PilecapTopView"
import PilecapSideView from "./PilecapSideView"
import { distance, getPolygonArea } from "lib/geometry"
import { getStruts } from "lib/pilecap/struts"

const CONCRETE_WEIGHT = 2.5

const PilecapCompleteModel = ({
  columns,
  normal,
  momentX,
  momentY,
  pileDiameter,
  initialPiles = false,
  showHull = true,
  pileCapacity,
  allowEditPiles,
  spacingBetweenPiles,
  considerPilecapWeight,
  showReactions,
  showStruts,
  showIsoareas,
}) => {
  const [piles, setPiles] = useState([])
  const [vertices, setVertices] = useState([])
  const [roundedVertices, setRoundedVertices] = useState([])
  const pilecapHeight = useRef(1)
  const previousSpacingBetweenPiles = useRef(spacingBetweenPiles)
  const pilecapWeight = useRef(0)
  const reactions = useRef([])
  const struts = useRef([])
  const isoareas = useRef([])

  useEffect(() => {
    if (initialPiles) {
      setPiles(initialPiles)
    } else {
      setPiles([])
    }
  }, [initialPiles])

  useEffect(() => {
    setPiles(piles =>
      piles.map(pile => {
        const factor = pileDiameter / pile.diameter
        return {
          ...pile,
          x: pile.x * factor,
          y: pile.y * factor,
          diameter: pileDiameter,
        }
      })
    )
  }, [pileDiameter])

  useEffect(() => {
    const factor = spacingBetweenPiles / previousSpacingBetweenPiles.current
    setPiles(piles =>
      piles.map(pile => {
        return {
          ...pile,
          x: pile.x * factor,
          y: pile.y * factor,
        }
      })
    )
    previousSpacingBetweenPiles.current = spacingBetweenPiles
  }, [spacingBetweenPiles])

  useEffect(() => {
    const hullVertices = getHullVertices(piles)
    const roundedHullVertices = getRoundedHullVertices(piles)
    setVertices(hullVertices)
    setRoundedVertices(roundedHullVertices)
    const height = getPilecapHeight(piles, columns)

    if (considerPilecapWeight) {
      const area = getPolygonArea(hullVertices)
      const volume = area * height
      const weight = volume * CONCRETE_WEIGHT

      pilecapWeight.current = weight
    }
    pilecapHeight.current = height
    reactions.current = getPilesReactions(piles, normal, momentX, momentY)

    const [_struts, _isoareas] = getStruts(piles, columns[0])
    struts.current = _struts
    isoareas.current = _isoareas
  }, [columns, considerPilecapWeight, momentX, momentY, normal, piles])

  const gridSpacing = (pileDiameter * spacingBetweenPiles) / 2

  return (
    <div
      className="w-100 relative"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
      }}
    >
      <PilecapTopView
        piles={piles}
        columns={columns}
        setPiles={setPiles}
        vertices={vertices}
        roundedVertices={roundedVertices}
        reactions={reactions.current}
        showHull={showHull}
        showGrid={allowEditPiles}
        gridSpacing={gridSpacing}
        cursorDiameter={pileDiameter}
        pileCapacity={pileCapacity}
        showCursor={allowEditPiles}
        pilecapWeight={pilecapWeight.current}
        struts={struts.current}
        isoareas={isoareas.current}
        showReactions={showReactions}
        showStruts={showStruts}
        showIsoareas={showIsoareas}
      />
      <PilecapSideView
        piles={piles}
        columns={columns}
        vertices={vertices}
        roundedVertices={roundedVertices}
        reactions={reactions.current}
        showHull={showHull}
        pileCapacity={pileCapacity}
        pilecapHeight={pilecapHeight.current}
        pilecapWeight={pilecapWeight.current}
        struts={struts.current}
        showReactions={showReactions}
        showStruts={showStruts}
        showIsoareas={showIsoareas}
      />
      {considerPilecapWeight && (
        <div
          className="absolute top-0 left-0 flex flex-column f1"
          style={{ pointerEvents: "none" }}
        >
          <div className="pa2">
            <span className="c-secondary">Peso</span>
            <span className="db">
              <span className="b">{pilecapWeight.current.toFixed(2)}</span>
              <span className="c-secondary">{" tf"}</span>
            </span>
          </div>
          <div className="pa2">
            <span className="c-secondary">Peso/N</span>
            <span className="db">
              <span className="b">
                {((pilecapWeight.current / normal) * 100).toFixed(0)}
              </span>
              <span className="c-secondary">{" %"}</span>
            </span>
          </div>
          <div className="pa2">
            <span className="c-secondary">Altura</span>
            <span className="db">
              <span className="b">{pilecapHeight.current.toFixed(2)}</span>
              <span className="c-secondary">{" m"}</span>
            </span>
          </div>
          {allowEditPiles && (
            <div className="pa2">
              <span className="c-secondary">Malha</span>
              <span className="db">
                <span className="b">
                  {`${gridSpacing.toFixed(2)} x ${gridSpacing.toFixed(2)}`}
                </span>
                <span className="c-secondary">{" m"}</span>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const getPilecapHeight = (
  piles,
  columns,
  minStrutAngle = Math.PI / 4,
  minHeight = 0.5
) =>
  Math.max(
    minHeight,
    Math.cos(minStrutAngle) *
      getMaxDistanceBetweenPilesAndColumns(piles, columns)
  )

const getMaxDistanceBetweenPilesAndColumns = (piles, columns) =>
  Math.max(
    ...piles.map(pile =>
      Math.max(...columns.map(column => distance(pile, column)))
    )
  )

export default PilecapCompleteModel
