import React from "react"
import { SW1, SW2, BORDER } from "drawing-components/styles"
import { Text, Arrow } from "drawing-components/svg-components"
import { rotatePoint, rotatePoints } from "lib/projection"
import { distance } from "lib/geometry"
import { getRectVertices, getCircleVertices } from "lib/utils"
import { interpolateLinear } from "lib/interpolation"

export const Columns = ({ viewAngle, columns, yBottom = 1, yTop = 1.5 }) => {
  const sorted = [...columns].sort((a, b) => {
    const rotatedColumnA = rotatePoint(viewAngle, a)
    const rotatedColumnB = rotatePoint(viewAngle, b)
    return rotatedColumnB.y - rotatedColumnA.y
  })

  return sorted.map(({ x, y, width, height }, index) => {
    const columnVertices = getRectVertices(x, y, width, height)
    const rotatedVertices = rotatePoints(viewAngle, columnVertices)

    return (
      <Faces
        key={index}
        vertices={rotatedVertices}
        height={yTop - yBottom}
        y0={yBottom}
      />
    )
  })
}

export const Piles = ({
  viewAngle,
  piles,
  reactions,
  pileCapacity,
  pilecapWeight,
  yBottom = -1,
  yTop = 0,
}) => {
  const pilesWithReactions = piles.map((pile, index) => ({
    ...pile,
    reaction: reactions[index],
  }))

  const rotatedPiles = rotatePoints(viewAngle, pilesWithReactions)
  const sorted = [...rotatedPiles].sort((a, b) => b.y - a.y)

  const numberOfPiles = piles.length
  const reactionDueToWeight =
    numberOfPiles > 0 ? pilecapWeight / numberOfPiles : 0

  return (
    <g>
      {sorted.map((pile, index) => {
        const reaction = pile.reaction || 0
        const totalReaction = reaction + reactionDueToWeight

        const isSafe =
          pileCapacity === null ||
          (totalReaction <= pileCapacity && totalReaction >= 0)

        const pilePoints = getCircleVertices(pile.x, pile.y, pile.diameter / 2)
        return (
          <Faces
            key={index}
            vertices={pilePoints}
            height={yTop - yBottom}
            y0={yBottom}
            saturation={isSafe ? 0 : 100}
          />
        )
      })}
    </g>
  )
}

export const Pile = ({ x, y, diameter, height, style }) => (
  <rect
    style={style}
    x={x - diameter / 2}
    y={-y}
    width={diameter}
    height={height}
  />
)

export const Edges = ({ vertices, dashed = false }) => {
  const style = {
    vectorEffect: "non-scaling-stroke",
    strokeWidth: dashed ? 0 : SW1,
    stroke: BORDER,
    strokeOpacity: 1,
    fill: BORDER,
    fillOpacity: 0.4,
    strokeDasharray: dashed && "2",
  }

  const points = vertices.map(({ x, y }) => [x, -y])
  return <polygon style={style} points={points} />
}

export const Faces = ({
  vertices,
  height = 1,
  y0 = 0,
  fadeIn = false,
  hue = 0,
  saturation = 0,
}) => {
  const style = {
    strokeWidth: 1,
    vectorEffect: "non-scaling-stroke",
  }

  const viewPoint = { x: 0, y: -3 }

  const averagePoint = (pointA, pointB) => ({
    x: (pointA.x + pointB.x) / 2,
    y: (pointA.y + pointB.y) / 2,
  })

  const minL = 40
  const maxL = 90

  const fieldDepth = 6

  const faces = vertices.map((point, index) => {
    const nextPoint = vertices[index + 1] || vertices[0]
    const dist = distance(viewPoint, averagePoint(point, nextPoint))
    const depth = interpolateLinear(dist, 0, fieldDepth, minL, maxL, true)

    return [
      depth,
      <rect
        key={index}
        className={fadeIn ? "fade-in-animation" : ""}
        style={{
          ...style,
          fill: `hsl(${hue}, ${saturation}%, ${depth}%)`,
          stroke: `hsl(${hue}, ${saturation}%, ${depth}%)`,
          transition: "fill 0.5",
        }}
        x={Math.min(point.x, nextPoint.x)}
        y={-(y0 + height)}
        width={Math.abs(point.x - nextPoint.x)}
        height={height}
      />,
    ]
  })

  return (
    <g>
      {[...faces]
        .sort((a, b) => a[0] - b[0])
        .reverse()
        .map(face => face[1])}
    </g>
  )
}

export const Reactions = ({
  viewAngle,
  piles,
  reactions,
  pilecapWeight,
  scale,
}) => {
  const viewPoint = { x: 0, y: -2 }
  const minL = 0
  const maxL = 90
  const fieldDepth = 4

  const pilesWithReactions = piles.map((pile, index) => ({
    ...pile,
    reaction: reactions[index],
  }))
  const rotatedPiles = rotatePoints(viewAngle, pilesWithReactions)

  const sorted = [...rotatedPiles].sort((a, b) => b.y - a.y)

  const y0 = 1
  const maxLength = 0.8
  const min = 0
  const max = Math.max(...reactions.map(r => Math.abs(r)))

  const numberOfPiles = piles.length
  const reactionDueToWeight =
    numberOfPiles > 0 ? pilecapWeight / numberOfPiles : 0

  return sorted.map((pile, index) => {
    let { reaction } = pile
    reaction = reaction + reactionDueToWeight

    let length = ((reaction - min) / (max - min)) * maxLength
    length = isNaN(length) ? y0 : length
    const dist = distance(viewPoint, pile)
    const depth = interpolateLinear(dist, 0, fieldDepth, minL, maxL, true)

    const label = reaction === Infinity ? "\u221e" : reaction?.toFixed(0)

    return (
      <g key={index}>
        <Arrow
          style={{
            stroke: `hsl(0, 0%, ${depth}%)`,
            fill: `hsl(0, 0%, ${depth}%)`,
          }}
          x1={pile.x}
          x2={pile.x}
          y1={y0 + length}
          y2={y0}
          scale={scale}
        />
        <Text
          style={{ fill: `hsl(348, 0%, ${depth}%)` }}
          x={pile.x}
          y={y0 + length}
          scale={scale}
          dominantBaseline={
            reaction < 0 ? "text-after-edge" : "text-before-edge"
          }
        >
          {label}
        </Text>
      </g>
    )
  })
}

export const Struts = ({ viewAngle, struts, pilecapHeight, scale }) => {
  const viewPoint = { x: 0, y: -2 }
  const maxDepth = 4
  const minLightness = 0
  const maxLightness = 70

  const getLightness = dist =>
    interpolateLinear(dist, 0, maxDepth, minLightness, maxLightness, true)

  const rotatedStruts = struts.map(strut => rotatePoints(viewAngle, strut))
  const sortedStruts = rotatedStruts.sort((a, b) => b[1].y - a[1].y)

  return sortedStruts.map((strut, index) => {
    const distFromViewPointToPile = distance(viewPoint, strut[1])
    const lightness = getLightness(distFromViewPointToPile)

    const style = {
      stroke: `hsl(0, 0%, ${lightness}%)`,
      fill: `hsl(0, 0%, ${lightness}%)`,
      strokeWidth: SW2,
    }

    return (
      <Strut
        key={index}
        style={style}
        strut={strut}
        pilecapHeight={pilecapHeight}
        scale={scale}
      />
    )
  })
}

const Strut = ({ style, strut, pilecapHeight, scale }) => (
  <Arrow
    style={style}
    x1={strut[0].x}
    x2={strut[1].x}
    y1={-pilecapHeight}
    y2={0}
    scale={scale}
  />
)
