import { BlockEquation } from "components/MathEquation";
import PilecapShowCase1 from "../../../../content/blog/disposicao-estacas-bloco-de-fundacao/PilecapShowCase1";
import PilecapShowCase2 from "../../../../content/blog/disposicao-estacas-bloco-de-fundacao/PilecapShowCase2";
import PilecapShowCase3 from "../../../../content/blog/disposicao-estacas-bloco-de-fundacao/PilecapShowCase3";
import * as React from 'react';
export default {
  BlockEquation,
  PilecapShowCase1,
  PilecapShowCase2,
  PilecapShowCase3,
  React
};