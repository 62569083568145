import React from "react"
import { SW1, PRIMARY, BORDER, FONT_STYLE } from "./styles"

export const Arrow = ({ x1, y1, x2, y2, scale, arrowSize = 7, style }) => {
  const arrowStyle = {
    vectorEffect: "non-scaling-stroke",
    strokeWidth: SW1,
    stroke: PRIMARY,
    ...style,
  }

  const arrowHeadPoints = [
    [-1.5, -0.5],
    [0, 0],
    [-1.5, 0.5],
  ]

  const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI //degrees

  const length = Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2))

  return (
    <g transform={`translate(${x1} ${y1}) rotate(${angle})`}>
      <line style={arrowStyle} x1={0} x2={length} y1={0} y2={0} />
      <polygon
        style={arrowStyle}
        points={arrowHeadPoints}
        transform={`translate(${length} 0) scale(${(1 / scale) * arrowSize})`}
      />
    </g>
  )
}

export const Text = ({
  children,
  x,
  y,
  scale,
  dy = 0,
  textAnchor = "middle",
  dominantBaseline = "middle",
  style,
}) => (
  <text
    style={{ ...FONT_STYLE, ...style }}
    textAnchor={textAnchor}
    dominantBaseline={dominantBaseline}
    x={0}
    y={dy}
    transform={`translate(${x} ${y}) scale(${1 / scale})`}
  >
    {children}
  </text>
)

export const Axis = ({ minX, maxX, minY, maxY }) => {
  const style = {
    strokeWidth: SW1,
    stroke: PRIMARY,
    vectorEffect: "non-scaling-stroke",
  }
  return (
    <g className="fade-in-animation">
      <HorizontalLine style={style} y={0} x1={minX} x2={maxX} />
      <VerticalLine style={style} x={0} y1={minY} y2={maxY} />
    </g>
  )
}

export const HorizontalLine = ({ style, x1, x2, y }) => (
  <line x1={x1} x2={x2} y1={y} y2={y} style={style} />
)

export const VerticalLine = ({ style, x, y1, y2 }) => (
  <line style={style} x1={x} x2={x} y1={y1} y2={y2} />
)

export const Grid = ({ gridSpacing, minX, maxX, minY, maxY }) => {
  const style = {
    strokeWidth: SW1,
    stroke: BORDER,
    vectorEffect: "non-scaling-stroke",
  }

  const numberOfPositiveVerticalLines = getNumberOfLines(maxX, gridSpacing)
  const numberOfNegativeVerticalLines = getNumberOfLines(minX, gridSpacing)
  const numberOfPositiveHorizontalLines = getNumberOfLines(maxY, gridSpacing)
  const numberOfNegativeHorizontalLines = getNumberOfLines(minY, gridSpacing)

  return (
    <g className="fade-in-animation">
      {renderSequence(numberOfPositiveVerticalLines, index => (
        <VerticalLine
          key={index}
          style={style}
          x={index * gridSpacing}
          y1={minY}
          y2={maxY}
        />
      ))}
      {renderSequence(numberOfNegativeVerticalLines, index => (
        <VerticalLine
          key={index}
          style={style}
          x={-index * gridSpacing}
          y1={minY}
          y2={maxY}
        />
      ))}
      {renderSequence(numberOfPositiveHorizontalLines, index => (
        <HorizontalLine
          key={index}
          style={style}
          y={index * gridSpacing}
          x1={minX}
          x2={maxX}
        />
      ))}
      {renderSequence(numberOfNegativeHorizontalLines, index => (
        <HorizontalLine
          key={index}
          style={style}
          y={-index * gridSpacing}
          x1={minX}
          x2={maxX}
        />
      ))}
    </g>
  )
}

const getNumberOfLines = (length, spacing) =>
  Math.ceil(Math.abs(length) / spacing)

const renderSequence = (sequenceLength, callback) =>
  [...Array(sequenceLength)].map((_, index) => callback(index))
