export const P3_INLINE_HORIZONTAL = [
  [0, 0],
  [-2, 0],
  [2, 0],
]

export const P3_INLINE_VERTICAL = [
  [0, 2],
  [0, 0],
  [0, -2],
]

export const P3_DEFAULT = [
  [0, 1],
  [-1, -1],
  [1, -1],
]

export const P4_DEFAULT = [
  [-1, 1],
  [-1, -1],
  [1, -1],
  [1, 1],
]

export const P4_INLINE_VERTICAL = [
  [0, 3],
  [0, -3],
  [0, -1],
  [0, 1],
]

export const P4_INLINE_HORIZONTAL = [
  [-3, 0],
  [-1, 0],
  [1, 0],
  [3, 0],
]

export const P6_2X3 = [
  [-2, 1],
  [-2, -1],
  [0, -1],
  [0, 1],
  [2, 1],
  [2, -1],
]

export const P6_2X3_POLYGON = [
  [-2, 1],
  [-2, -1],
  [0, -2],
  [0, 2],
  [2, 1],
  [2, -1],
]

export const P6_3X2 = [
  [-1, 0],
  [-1, -2],
  [1, -2],
  [-1, 2],
  [1, 2],
  [1, 0],
]

export const P6_3X2_POLYGON = [
  [-2, 0],
  [-1, -2],
  [1, -2],
  [-1, 2],
  [1, 2],
  [2, 0],
]

export const P6_TRANSITION_TO_P3 = [
  [-1, -1],
  [-1, -1],
  [1, -1],
  [0, 1],
  [0, 1],
  [1, -1],
]

export const P8_2X4 = [
  [-3, 1],
  [-3, -1],
  [-1, -1],
  [-1, 1],
  [1, 1],
  [1, -1],
  [3, 1],
  [3, -1],
]

export const P8_3X2X3 = [
  [-2, 0],
  [-2, -2],
  [0, -2],
  [-2, 2],
  [0, 2],
  [2, -2],
  [2, 2],
  [2, 0],
]

export const P8_4X2 = [
  [-1, 1],
  [-1, -1],
  [-1, -3],
  [-1, 3],
  [1, 3],
  [1, -3],
  [1, 1],
  [1, -1],
]

export const P8_2X4_TRANSITION_TO_P3 = [
  [0, 1],
  [-1, -1],
  [1, -1],
  [0, 1],
  [0, 1],
  [1, -1],
  [0, 1],
  [1, -1],
]
