import React from "react"
import SideViewModel from "drawing-components/Pilecap/SideViewModel"

const PilecapSideView = props => {
  return (
    <div className="w-100 border-on-hover">
      <SideViewModel {...props} />
    </div>
  )
}

export default PilecapSideView
