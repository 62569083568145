import { useState, useEffect } from "react"

const useInterval = (duration, delay = 0, fps = 20) => {
  const [state, setState] = useState({ step: 0, play: true })

  const nframes = parseInt((duration / 1000) * fps)
  const interval = duration / nframes

  useEffect(() => {
    let intervalId

    if (state.play) {
      intervalId = setInterval(() => {
        setState(({ step }) => {
          const nextStep = (step + 1) % (nframes + 1)

          return {
            step: nextStep,
            play: nextStep !== 0,
          }
        })
      }, interval)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [nframes, state.play, interval])

  useEffect(() => {
    let timeoutId
    if (!state.play) {
      setTimeout(() => setState(state => ({ ...state, play: true })), delay)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [delay, state.play])

  return [nframes, state.step]
}

export default useInterval
