import React from "react"
import PilecapShowCase from "drawing-components/Pilecap/PilecapShowCase"
import {
  P3_INLINE_HORIZONTAL,
  P3_INLINE_VERTICAL,
  P3_DEFAULT,
  P4_DEFAULT,
  P4_INLINE_VERTICAL,
  P4_INLINE_HORIZONTAL,
  P6_2X3,
  P6_2X3_POLYGON,
  P6_3X2,
  P6_3X2_POLYGON,
  P8_2X4,
  P8_3X2X3,
  P8_4X2,
  P8_2X4_TRANSITION_TO_P3,
} from "./piles-layouts"

const pilecase_3piles = [
  P3_DEFAULT,
  P3_INLINE_HORIZONTAL,
  P3_DEFAULT,
  P3_INLINE_VERTICAL,
  P3_DEFAULT,
]

const pilecase_4piles = [
  P4_DEFAULT,
  P4_INLINE_VERTICAL,
  P4_DEFAULT,
  P4_INLINE_HORIZONTAL,
  P4_DEFAULT,
]

const pilecase_6piles = [P6_2X3, P6_2X3_POLYGON, P6_3X2, P6_3X2_POLYGON, P6_2X3]

const pilecase_8piles = [P8_2X4, P8_4X2, P8_3X2X3, P8_2X4]

const allcases = [
  P3_DEFAULT,
  P3_INLINE_VERTICAL,
  P3_INLINE_HORIZONTAL,
  P3_DEFAULT,
  P4_DEFAULT,
  P4_INLINE_VERTICAL,
  P4_INLINE_HORIZONTAL,
  P4_DEFAULT,
  P6_2X3,
  P6_2X3_POLYGON,
  P6_3X2,
  P6_3X2_POLYGON,
  P6_2X3,
  P8_2X4,
  P8_4X2,
  P8_3X2X3,
  P8_2X4,
  P8_2X4_TRANSITION_TO_P3,
]

const SHOW_CASES_COORDINATES = [
  allcases,
  pilecase_3piles,
  pilecase_4piles,
  pilecase_6piles,
  pilecase_8piles,
]
const SHOW_CASES_LABELS = ["Loop", "3", "4", "6", "8"]

const PILE_DIAMETER = 0.4

const StrutsShowCase = () => (
  <PilecapShowCase
    showCasesCoordinates={SHOW_CASES_COORDINATES}
    showCasesLabels={SHOW_CASES_LABELS}
    pileDiameter={PILE_DIAMETER}
    allowEditColumn
    allowEditPileDiameter
    allowEditSpacingBetweenPiles
    considerPilecapWeight={false}
    freeMode
    showStruts
    showIsoareas
  />
)

export default StrutsShowCase
