export const extendPoints = (pointA, pointB, offset) => {
  const lineLength = distance(pointA, pointB)
  const cos = (pointA.x - pointB.x) / lineLength
  const sin = (pointA.y - pointB.y) / lineLength

  const dx = offset * Math.abs(cos)
  const dy = offset * Math.abs(sin)

  const isPointAToTheRight = pointA.x > pointB.x
  const isPointAToTheTop = pointA.y > pointB.y

  return [
    {
      x: pointA.x + dx * (isPointAToTheRight ? 1 : -1),
      y: pointA.y + dy * (isPointAToTheTop ? 1 : -1),
    },
    {
      x: pointB.x + dx * (!isPointAToTheRight ? 1 : -1),
      y: pointB.y + dy * (!isPointAToTheTop ? 1 : -1),
    },
  ]
}

export const distance = (pointA, pointB) => {
  return Math.sqrt(
    Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2)
  )
}

export const offsetPolygon = (points, offset = 0.4) => {
  const offsetedPoints = points.map((point, index) => {
    const previousPoint = points[index - 1] || points[points.length - 1]
    const nextPoint = points[index + 1] || points[0]

    const [pointA, pointB] = offsetLine(offset, previousPoint, point)
    const [pointC, pointD] = offsetLine(offset, point, nextPoint)

    return getIntersection(pointA, pointB, pointC, pointD)
  })

  return offsetedPoints
}

const getIntersection = (pointA, pointB, pointC, pointD) => {
  // Line AB represented as a1x + b1y = c1
  const a1 = pointB.y - pointA.y
  const b1 = pointA.x - pointB.x
  const c1 = a1 * pointA.x + b1 * pointA.y

  // Line CD represented as a2x + b2y = c2
  const a2 = pointD.y - pointC.y
  const b2 = pointC.x - pointD.x
  const c2 = a2 * pointC.x + b2 * pointC.y

  const determinant = a1 * b2 - a2 * b1

  if (determinant === 0) {
    return false
  } else {
    const x = (b2 * c1 - b1 * c2) / determinant
    const y = (a1 * c2 - a2 * c1) / determinant
    return { x, y }
  }
}

export const offsetLine = (offset, pointA, pointB) => {
  // the sign of the normal is used to know whether the offset should
  //  be to one side or the other
  const [normalX, normalY] = getLineNormal(pointA, pointB)

  const [ux, uy] = offsetUnitComponents(pointA, pointB)
  const dx = ux * offset * Math.sign(normalX)
  const dy = uy * offset * Math.sign(-normalY)

  return [pointA, pointB].map(point => ({ x: point.x + dx, y: point.y + dy }))
}

const getLineNormal = (pointA, pointB) => {
  const normalX = pointB.y - pointA.y
  const normalY = pointB.x - pointA.x
  return [normalX, normalY]
}

const offsetUnitComponents = (pointA, pointB) => {
  let ux, uy
  if (pointA.x === pointB.x) {
    // vertical line
    ux = 1
    uy = 0
  } else if (pointA.y === pointB.y) {
    // horizontal line
    ux = 0
    uy = 1
  } else {
    const line_m = (pointA.y - pointB.y) / (pointA.x - pointB.x)
    const ortho_line_m = -1 / line_m
    const ortho_angle = Math.atan(ortho_line_m)
    ux = Math.abs(Math.cos(ortho_angle))
    uy = Math.abs(Math.sin(ortho_angle))
  }

  return [ux, uy]
}

export const getPolygonArea = vertices => {
  const numberOfVertices = vertices.length
  let area = 0
  for (let i = 0; i < numberOfVertices; i++) {
    const j = (i + 1) % numberOfVertices
    area += vertices[i].x * vertices[j].y
    area -= vertices[j].x * vertices[i].y
  }
  area = Math.abs(area) / 2
  return area
}
