import { sortPoints, getRectVertices, getCircleVertices } from "../utils"
import { extendPoints, offsetLine, offsetPolygon } from "../geometry"
import { convexHull } from "../convex-hull"

export const getHullVertices = piles => {
  const pilesHull = convexHull(piles)
  const pileDiameter = piles[0]?.diameter

  if (piles.length === 1) {
    return getVerticesForOnePile(piles, pileDiameter)
  } else if (pilesHull.length === 2) {
    return getVerticesForTwoPiles(piles, pileDiameter)
  } else if (pilesHull.length === 3) {
    return getChamferVertices(pilesHull, pileDiameter * 1.5)
  } else {
    return offsetPolygon(pilesHull, pileDiameter)
  }
}

export const getRoundedHullVertices = piles => {
  if (convexHull(piles) < 2) {
    return []
  }

  const pileDiameter = piles[0].diameter
  const offset = pileDiameter / 2

  const roundedHull = convexHull(
    piles
      .map(pile => getCircleVertices(pile.x, pile.y, pile.diameter / 2))
      .flat()
  )

  return offsetPolygon(roundedHull, offset)
}

const getVerticesForOnePile = (piles, offset = 0.4) => {
  const pile = piles[0]
  const width = 2 * offset
  const height = width

  return getRectVertices(pile.x, pile.y, width, height)
}

const getVerticesForTwoPiles = (piles, offset = 0.4) => {
  const sorted = sortPoints(piles)
  const pointA = sorted[0]
  const pointB = sorted[sorted.length - 1]
  const [pointAExtended, pointBExtended] = extendPoints(pointA, pointB, offset)

  let vertices = []
  vertices.push(...offsetLine(offset, pointAExtended, pointBExtended))
  vertices.push(
    ...offsetLine(-offset, pointAExtended, pointBExtended).reverse()
  )

  return vertices
}

export const getChamferVertices = (points, offset = 0.4) => {
  let vertices = []
  for (let i = 0; i < points.length; i++) {
    const previousPoint = points[i - 1] || points[points.length - 1]
    const currentPoint = points[i]
    const nextPoint = points[i + 1] || points[0]
    const pointB = extendPoints(previousPoint, currentPoint, offset)[1]
    const pointC = extendPoints(currentPoint, nextPoint, offset)[0]
    vertices.push(pointC, pointB)
  }

  return vertices
}
