import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Grid, Axis } from "./svg-components"
// import SvgToPng from "./SvgToPng";
// import GridInfo from "./GridInfo"

const DEFAULT_GRID_SPACING = 0.5 // in meters
const SCALE_INCREMENT = 0.1

// remember that the Y-axis from <svg> is inversed. So every value from our API
// must use a negative y value

// The text is rendered in the svg viewbox units, so if fontSize = 1, that means
// that the font size will be 1 meter in height. To fix this issue, I draw a line
// with one meter in length, then I use the getBoundingClientRect().width to get
// its length in pixels. 1 meter = 1 screenScale pixels.

const SVG = ({
  children,
  cursor,
  onClick,
  onMouseMove,
  minX = -3,
  maxX = 3,
  minY = -3,
  maxY = 3,
  width = "100%",
  height = "100%",
  showGrid = true,
  gridSpacing = DEFAULT_GRID_SPACING,
  snapToGrid = false,
  showCursor = false,
  toPng = false,
  alt = "",
}) => {
  const svgRef = useRef(null)
  const oneMeterLineRef = useRef(null)
  const [scale, setScale] = useState(1)
  const [screenScale, setScreenScale] = useState(1000)
  const [mouse, setMouse] = useState({ x: 0, y: 0, screenX: 0, screenY: 0 })
  const [pan, setPan] = useState({
    panStartX: 0,
    panStartY: 0,
    dx: 0,
    dy: 0,
  })
  const [isDragging, setIsDragging] = useState(false)
  const [mouseIsInside, setMouseIsInside] = useState(false)
  const [showSVG, setShowSVG] = useState(true)

  const viewBoxWidth = maxX - minX
  const viewBoxHeight = maxY - minY

  useEffect(() => {
    if (toPng) {
      setShowSVG(false)
    }
  }, [toPng])

  useEffect(() => {
    if (svgRef.current !== null) updateScreenScale()
  })

  useEffect(() => {
    window.addEventListener("resize", updateScreenScale)
    return () => window.removeEventListener("resize", updateScreenScale)
  })

  const updateScreenScale = () => {
    setScreenScale(oneMeterLineRef.current.getBoundingClientRect().width)
  }

  const handleOnClick = () => {
    if (onClick && showCursor) {
      return onClick({ scale, mouse, ref: svgRef })
    }
  }

  const handleWheel = event => {
    const deltaScale = SCALE_INCREMENT * (event.deltaY < 0 ? 1 : -1)
    const minScale = SCALE_INCREMENT
    setScale(Math.max(scale + deltaScale, minScale))
  }

  const onMouseDown = event => {
    const panStartX = event.clientX
    const panStartY = event.clientY

    setIsDragging(true)
    setPan({
      ...pan,
      panStartX,
      panStartY,
    })
  }

  const handleOnMouseMove = event => {
    if (isDragging) {
      const panEndX = event.clientX
      const panEndY = event.clientY

      const moveDeltaX = (panEndX - pan.panStartX) / (screenScale * scale)
      const moveDeltaY = (panEndY - pan.panStartY) / (screenScale * scale)

      setPan({
        panStartX: panEndX,
        panStartY: panEndY,
        dx: pan.dx + moveDeltaX,
        dy: pan.dy + moveDeltaY,
      })
    }

    const [mouseX, mouseY] = getRelativeCoordinates(event)
    setMouse({
      x: snapToGrid ? getSnapToGridValue(mouseX) : mouseX,
      y: snapToGrid ? getSnapToGridValue(mouseY) : mouseY,
      screenX: event.clientX,
      screenY: event.clientY,
    })

    if (onMouseMove) {
      return onMouseMove({ scale, mouse })
    }
  }

  const onMouseUp = () => {
    setIsDragging(false)
  }

  const onMouseEnter = () => {
    document.body.style.overflowY = "hidden"
    setMouseIsInside(true)
  }

  const onMouseLeave = () => {
    document.body.style.overflowY = "auto"
    setIsDragging(false)
    setMouseIsInside(false)
  }

  const getRelativeCoordinates = event => {
    const svg = svgRef.current
    const pt = svg.createSVGPoint()

    pt.x = event.clientX
    pt.y = event.clientY

    // The cursor point, translated into svg coordinates
    const cursorpt = pt.matrixTransform(svg.getScreenCTM().inverse())
    const valueX = cursorpt.x / scale - pan.dx
    const valueY = cursorpt.y / scale - pan.dy
    return [valueX, -valueY]
  }

  const getSnapToGridValue = value => {
    return Math.round(value / gridSpacing) * gridSpacing
  }

  return (
    <div className="w-100 h-100 relative">
      <svg
        style={{
          cursor: showCursor ? "crosshair" : "move",
          display: showSVG ? "block" : "none",
        }}
        width={width}
        height={height}
        viewBox={`${minX} ${minY} ${viewBoxWidth} ${viewBoxHeight}`}
        preserveAspectRatio="xMidYMid slice"
        onClick={handleOnClick}
        onWheel={event => handleWheel(event)}
        onMouseDown={event => onMouseDown(event)}
        onMouseMove={event => handleOnMouseMove(event)}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={svgRef}
      >
        <line
          strokeWidth={0}
          x1={0}
          x2={1}
          y1={0}
          y2={0}
          ref={oneMeterLineRef}
        />
        <g transform={`scale(${scale}) translate(${pan.dx} ${pan.dy})`}>
          {showGrid && (
            <g>
              <Grid
                gridSpacing={gridSpacing}
                {...getAdjustedLimits(minX, maxX, minY, maxY, scale, pan)}
              />
              <Axis
                {...getAdjustedLimits(minX, maxX, minY, maxY, scale, pan)}
              />
            </g>
          )}
          {/* render prop. children and cursor must be functions */}
          {children({ scale, screenScale, mouse })}
          {showCursor && mouseIsInside && cursor({ scale, screenScale, mouse })}
        </g>
      </svg>
      {/* {showGrid && <GridInfo gridSpacing={gridSpacing} />} */}
      {/* {toPng && (
        <SvgToPng
          svg={svgRef.current}
          width={width}
          height={height}
          alt={alt}
        />
      )} */}
    </div>
  )
}

const getAdjustedLimits = (minX, maxX, minY, maxY, scale, pan) => {
  // Compensate for the scale and pan so the grid and axis always
  // fill the whole viewBox
  return {
    minX: minX / scale - pan.dx,
    maxX: maxX / scale - pan.dx,
    minY: minY / scale - pan.dy,
    maxY: maxY / scale - pan.dy,
  }
}

SVG.propTypes = {
  children: PropTypes.func.isRequired,
  cursor: PropTypes.func,
  onClick: PropTypes.func,
  onMouseMove: PropTypes.func,
}

export default SVG
