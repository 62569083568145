import React, { useState, useEffect } from "react"
import SVG from "drawing-components/SVG"
import { Piles, Faces, Reactions, Columns, Struts } from "./components"
import { rotatePoints } from "lib/projection"

const INCREMENT = Math.PI / 500
const INTERVAL = 1000 / 30 //ms

const SideViewModel = ({
  piles,
  columns,
  vertices,
  roundedVertices,
  reactions,
  showHull,
  pileCapacity,
  pilecapHeight,
  pilecapWeight,
  struts,
  showReactions = false,
  showStruts = false,
  ...rest
}) => {
  const [viewAngle, setViewAngle] = useState(0)
  const [autoRotate, setAutoRotate] = useState(true)
  const [fadeIn, setFadeIn] = useState(true)

  useEffect(() => {
    let intervalId
    if (autoRotate) {
      intervalId = setInterval(
        () =>
          setViewAngle(viewAngle => (viewAngle + INCREMENT) % (2 * Math.PI)),
        INTERVAL
      )
    }

    return () => clearTimeout(intervalId)
  }, [autoRotate])

  useEffect(() => {
    let timeoutId

    if (showHull) {
      timeoutId = setTimeout(() => setFadeIn(false), 500)
    } else {
      setFadeIn(true)
    }

    return () => clearTimeout(timeoutId)
  }, [showHull])

  return (
    <div className="h-100 relative">
      <SVG showGrid={false} {...rest}>
        {({ scale, screenScale }) => {
          const textScale = scale * screenScale
          return (
            <React.Fragment>
              <Piles
                piles={piles}
                viewAngle={viewAngle}
                reactions={reactions}
                pileCapacity={pileCapacity}
                pilecapWeight={pilecapWeight}
              />
              <Columns
                columns={columns}
                viewAngle={viewAngle}
                yBottom={pilecapHeight}
                yTop={pilecapHeight + 0.5}
              />
              {showHull && (
                <Faces
                  vertices={rotatePoints(viewAngle, roundedVertices)}
                  height={pilecapHeight}
                  fadeIn={fadeIn}
                />
              )}
              {showHull && (
                <Faces
                  vertices={rotatePoints(viewAngle, vertices)}
                  height={pilecapHeight}
                  fadeIn={fadeIn}
                />
              )}
              {showReactions && (
                <Reactions
                  viewAngle={viewAngle}
                  piles={piles}
                  reactions={reactions}
                  pilecapWeight={pilecapWeight}
                  scale={textScale}
                />
              )}
              {showHull && showStruts && (
                <Struts
                  viewAngle={viewAngle}
                  struts={struts}
                  pilecapHeight={pilecapHeight}
                  scale={textScale}
                />
              )}
            </React.Fragment>
          )
        }}
      </SVG>
      <div className="absolute w-100" style={{ bottom: "-8px" }}>
        <input
          className="w-100 slider"
          type="range"
          value={viewAngle}
          min={0}
          max={2 * Math.PI}
          step={Math.PI / 32}
          onMouseEnter={() => setAutoRotate(false)}
          onMouseLeave={() => setAutoRotate(true)}
          onTouchStart={() => setAutoRotate(false)}
          onTouchEnd={() => setAutoRotate(true)}
          onChange={e => setViewAngle(parseFloat(e.target.value))}
        />
      </div>
    </div>
  )
}

export default SideViewModel
