import React, { useState } from "react"
import { almostEq } from "lib/utils"
import TopViewModel from "../TopViewModel"
import { AddPileCursor, RemovePileCursor } from "../TopViewModel/components"

const PilecapTopView = ({ piles, setPiles, cursorDiameter, ...rest }) => {
  const [pileCursorIsDuplicated, setPileCursorIsDuplicated] = useState(false)
  const [pileCursor, setPileCursor] = useState(null)

  const onClick = ({ mouse }) => {
    const mousePile = getMousePile(mouse, piles, cursorDiameter)

    if (mousePile.isDuplicated) {
      setPiles(piles.filter((_, index) => index !== mousePile.duplicatedIndex))
    } else {
      setPiles([...piles, mousePile.pile])
    }
  }

  const onMouseMove = ({ mouse }) => {
    const { isDuplicated, pile } = getMousePile(mouse, piles, cursorDiameter)
    setPileCursor(pile)
    setPileCursorIsDuplicated(isDuplicated)
  }

  return (
    <div className="border-on-hover w-100">
      <TopViewModel
        piles={piles}
        {...rest}
        cursor={() => (
          <Cursor
            pile={pileCursor}
            pileCursorIsDuplicated={pileCursorIsDuplicated}
          />
        )}
        snapToGrid
        onClick={onClick}
        onMouseMove={onMouseMove}
      />
    </div>
  )
}

const getMousePile = (mouse, piles, cursorDiameter) => {
  const pile = { x: mouse.x, y: mouse.y, diameter: cursorDiameter }
  const duplicatedIndex = getDuplicatedPileIndex(piles, pile)
  const isDuplicated = duplicatedIndex !== -1

  return {
    pile,
    duplicatedIndex,
    isDuplicated,
  }
}

const Cursor = ({ pile, pileCursorIsDuplicated }) => {
  if (pile === null) return null

  return pileCursorIsDuplicated ? (
    <RemovePileCursor pile={pile} />
  ) : (
    <AddPileCursor pile={pile} />
  )
}

const getDuplicatedPileIndex = (piles, newPile) => {
  return piles.findIndex(
    pile => almostEq(pile.x, newPile.x) && almostEq(pile.y, newPile.y)
  )
}

export default PilecapTopView
