export const almostEq = (a, b, delta = 0.01) => {
  return Math.abs(a - b) <= delta
}

export const sortPoints = points => {
  const comparePoints = (pointA, pointB) => {
    if (pointA.x === pointB.x) {
      return pointA.y - pointB.y
    }
    return pointA.x - pointB.x
  }

  const copy = [...points]
  return [...copy.sort(comparePoints)]
}

export const getRectVertices = (xg, yg, width, height) => {
  const dx = width / 2
  const dy = height / 2
  return [
    {
      x: xg + dx,
      y: yg + dy,
    },
    {
      x: xg - dx,
      y: yg + dy,
    },
    {
      x: xg - dx,
      y: yg - dy,
    },
    {
      x: xg + dx,
      y: yg - dy,
    },
  ]
}

export const getCircleVertices = (xg, yg, r, npoints = 16) => {
  let points = []
  const dTheta = (2 * Math.PI) / npoints
  for (let i = 0; i < npoints; i++) {
    points.push({
      x: xg + r * Math.cos(i * dTheta),
      y: yg + r * Math.sin(i * dTheta),
    })
  }
  return points
}
