export const SW1 = 1
export const SW2 = 2
export const PRIMARY = "#333"
export const BORDER = "#e0e0e0"
export const ERROR = "#ff5252"
export const HIGHLIGHT = "#ffe57f"
export const DASH = "5 10"

export const FONT_SIZE = 14
export const FONT_WEIGHT = 700
export const FONT_STYLE = { fontSize: FONT_SIZE, fontWeight: FONT_WEIGHT }
