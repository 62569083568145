import React, { useState, useEffect, useRef } from "react"
import Button from "ui/Button"
import Input from "ui/Input"
import useInterval from "hooks/useInterval"
import { getArrayInterpolationStep } from "lib/interpolation"
import CompleteModel from "./CompleteModel"

const INPUT_LABEL = {
  bx: "Bx (m)",
  by: "By (m)",
  pileDiameter: "\u00d8 (m)",
  normal: "N (tf)",
  momentX: "Mx (tf.m)",
  momentY: "My (tf.m)",
  pileCapacity: "Cap. (tf)",
  spacingBetweenPiles: "Dist (x \u00d8)",
}

const INPUT_STEP = {
  bx: 0.05,
  by: 0.05,
  pileDiameter: 0.01,
  spacingBetweenPiles: 0.1,
}

const INPUT_MIN = {
  bx: 0.1,
  by: 0.1,
  pileCapacity: 0,
  pileDiameter: 0.2,
  spacingBetweenPiles: 1,
}

export const PilecapShowCase = ({
  showCasesCoordinates,
  showCasesLabels,
  defaultBx = 0.5,
  defaultBy = 0.5,
  defaultNormal = null, // if null, input will not be displayed
  defaultMomentX = null,
  defaultMomentY = null,
  defaultPileCapacity = null,
  defaultPileDiameter = 0.5,
  defaultSpacingBetweenPiles = 3,
  allowEditPileDiameter = false,
  allowEditSpacingBetweenPiles = false,
  allowEditColumn = false,
  considerPilecapWeight = false,
  freeMode = false,
  showReactions,
  showStruts,
  showIsoareas,
}) => {
  const showCases = useRef(
    convertShowCasesCoordinatesToPiles(
      showCasesCoordinates,
      defaultPileDiameter,
      (defaultSpacingBetweenPiles * defaultPileDiameter) / 2
    )
  )
  const layoutIndex = useRef(0) // useRef means that when this value updated, it should not trigger a new render

  const [caseIndex, setCaseIndex] = useState(0)
  const [piles, setPiles] = useState(showCases.current[0][0])
  const [showHull, setShowHull] = useState(true)
  const [allowEditPiles, setAllowEditPiles] = useState(false)

  const [state, setState] = useState({
    bx: defaultBx,
    by: defaultBy,
    pileDiameter: defaultPileDiameter,
    normal: defaultNormal,
    momentX: defaultMomentX,
    momentY: defaultMomentY,
    pileCapacity: defaultPileCapacity,
    spacingBetweenPiles: defaultSpacingBetweenPiles,
  })

  const INPUT_SHOW_CONDITION = {
    bx: allowEditColumn,
    by: allowEditColumn,
    pileDiameter: allowEditPileDiameter,
    normal: state.normal !== null,
    momentX: state.momentX !== null,
    momentY: state.momentY !== null,
    pileCapacity: state.pileCapacity !== null,
    spacingBetweenPiles: allowEditSpacingBetweenPiles,
  }

  const [nsteps, step] = useInterval(400, 800)

  useEffect(() => {
    const gridSpacing = (state.spacingBetweenPiles / 2) * state.pileDiameter
    showCases.current = convertShowCasesCoordinatesToPiles(
      showCasesCoordinates,
      state.pileDiameter,
      gridSpacing
    )
  }, [showCasesCoordinates, state.pileDiameter, state.spacingBetweenPiles])

  useEffect(() => {
    layoutIndex.current = 0
  }, [caseIndex])

  useEffect(() => {
    if (allowEditPiles) return

    if (step === 0) {
      layoutIndex.current =
        (layoutIndex.current + 1) % (showCases.current[caseIndex].length - 1)
    }

    const newPiles = getArrayInterpolationStep(
      showCases.current[caseIndex][layoutIndex.current],
      showCases.current[caseIndex][layoutIndex.current + 1],
      nsteps,
      step
    )
    setPiles(newPiles)
    setShowHull(step === 0 || step === nsteps)
  }, [caseIndex, nsteps, step, allowEditPiles])

  const columns = [{ x: 0, y: 0, width: state.bx, height: state.by }]

  const setInput = event => {
    const { id, value } = event.target
    setState({ ...state, [id]: value })
  }

  return (
    <div className="full-bleed mv5">
      <div className="w-100 pv4 flex flex-wrap">
        <div className="mr3 mt3">
          <span className="db mt0 mb2 c-secondary">Estacas</span>
          {showCases.current.map((_, index) => (
            <Button
              key={index}
              onClick={() => {
                setCaseIndex(index)
                setAllowEditPiles(false)
              }}
              selected={caseIndex === index && !allowEditPiles}
            >
              {showCasesLabels[index]}
            </Button>
          ))}
          {freeMode && (
            <Button
              onClick={() => setAllowEditPiles(true)}
              selected={allowEditPiles}
            >
              Livre
            </Button>
          )}
        </div>
        <div
          className="mt3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(128px, 1fr))",
            columnGap: "1rem",
            rowGap: "1rem",
            flexGrow: 1,
          }}
        >
          {Object.entries(state).map(([id, value]) => {
            if (!INPUT_SHOW_CONDITION[id]) {
              return null
            }

            return (
              <Input
                key={id}
                id={id}
                label={INPUT_LABEL[id]}
                value={value}
                onChange={setInput}
                min={INPUT_MIN[id]}
                step={INPUT_STEP[id]}
              />
            )
          })}
        </div>
      </div>

      <CompleteModel
        columns={columns}
        normal={state.normal}
        momentX={state.momentX}
        momentY={state.momentY}
        initialPiles={!allowEditPiles && piles}
        showHull={allowEditPiles || showHull}
        pileDiameter={state.pileDiameter}
        spacingBetweenPiles={state.spacingBetweenPiles}
        pileCapacity={state.pileCapacity}
        allowEditPiles={allowEditPiles}
        considerPilecapWeight={considerPilecapWeight}
        showReactions={showReactions}
        showStruts={showStruts}
        showIsoareas={showIsoareas}
      />
    </div>
  )
}

const toPile = ([x, y], diameter, gridSpacing) => ({
  x: x * gridSpacing,
  y: y * gridSpacing,
  diameter,
})

const convertShowCasesCoordinatesToPiles = (
  showCasesCoordinates,
  pileDiameter,
  gridSpacing
) =>
  showCasesCoordinates.map(pileCase =>
    pileCase.map(pilecap =>
      pilecap.map(pile => toPile(pile, pileDiameter, gridSpacing))
    )
  )

export default PilecapShowCase
