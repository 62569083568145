export const sortPiles = piles => {
  // sort piles top to bottom, left to right.
  const rowsYCoord = new Set(piles.map(e => e.y))
  const sortedYCoord = [...rowsYCoord].sort((a, b) => b - a) // decreasing order

  let sortedPiles = []

  for (let yValue of sortedYCoord) {
    let row = []
    for (let pile of piles) {
      if (pile.y === yValue) {
        row.push(pile)
      }
    }

    row.sort((a, b) => a.x - b.x)

    sortedPiles.push(row)
  }

  return sortedPiles
}

export const getStruts = (piles, column) => {
  let struts = []
  let isoareas = []

  if (piles.length === 0) {
    return [struts, isoareas]
  }

  const columnArea = column.width * column.height

  const isoarea = columnArea / piles.length

  const sortedPiles = sortPiles(piles)

  const columnTopLeftCorner = {
    x: column.x - column.width / 2,
    y: column.y + column.height / 2,
  }

  let rowTopY = columnTopLeftCorner.y

  for (let row of sortedPiles) {
    let isoareaWidth = column.width / row.length
    let isoareaHeight = isoarea / isoareaWidth
    let isoareaY = rowTopY - isoareaHeight / 2

    for (let i = 0; i < row.length; i++) {
      const pile = row[i]
      let isoareaX = columnTopLeftCorner.x + (i + 1 / 2) * isoareaWidth

      struts.push([
        { x: isoareaX, y: isoareaY },
        { x: pile.x, y: pile.y },
      ])

      isoareas.push({
        x: isoareaX,
        y: isoareaY,
        width: isoareaWidth,
        height: isoareaHeight,
      })
    }

    rowTopY -= isoareaHeight
  }

  return [struts, isoareas]
}
