import { almostEq } from "lib/utils"

export const getPilesReactions = (piles, normal, momentX, momentY) => {
  const getSum = (sum, num) => sum + num
  const sumX2 = piles.map(p => Math.pow(p.x, 2)).reduce(getSum, 0)
  const sumY2 = piles.map(p => Math.pow(p.y, 2)).reduce(getSum, 0)
  const npiles = piles.length

  return piles.map(pile => {
    const rMx = getMomentComponent(momentX, pile.y, sumY2)
    const rMy = getMomentComponent(momentY, pile.x, sumX2)

    return normal / npiles + rMx + rMy
  })
}

const getMomentComponent = (moment, x, sumX2) => {
  if (almostEq(moment, 0)) return 0

  const reaction = (x * moment) / sumX2

  return isNaN(reaction) ? Infinity : reaction
}
