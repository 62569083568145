import React from "react"
import SVG from "drawing-components/SVG"
import {
  Piles,
  Edges,
  PilesReactions,
  Columns,
  Struts,
  Isoareas,
} from "./components"

const PilecapTopViewModel = ({
  piles,
  columns,
  vertices,
  roundedVertices,
  reactions,
  struts,
  isoareas,
  showHull,
  pileCapacity,
  pilecapWeight,
  showReactions = false,
  showStruts = false,
  showIsoareas = false,
  ...rest
}) => {
  return (
    <SVG {...rest}>
      {({ scale, screenScale }) => {
        const textScale = scale * screenScale
        return (
          <React.Fragment>
            {roundedVertices && showHull && (
              <Edges vertices={roundedVertices} dashed />
            )}
            {showHull && <Edges vertices={vertices} />}
            <Columns columns={columns} />
            <Piles
              piles={piles}
              reactions={reactions}
              pilecapWeight={pilecapWeight}
              pileCapacity={pileCapacity}
            />
            {showReactions && (
              <PilesReactions
                piles={piles}
                reactions={reactions}
                pilecapWeight={pilecapWeight}
                scale={textScale}
              />
            )}
            {showHull && showStruts && (
              <Struts struts={struts} scale={textScale} />
            )}
            {showHull && showIsoareas && <Isoareas isoareas={isoareas} />}
          </React.Fragment>
        )
      }}
    </SVG>
  )
}

export default PilecapTopViewModel
