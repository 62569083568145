import React from "react"

const Button = ({ children, onClick, selected = false }) => (
  <button
    className={`min-w-tap ph2 pv0 h-tap ba pointer b outline-0 bg-foreground ${
      selected ? "b--primary bw1" : "b--border"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
)

export default Button
