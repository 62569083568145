export const getArrayInterpolationStep = (
  initialArray,
  finalArray,
  nsteps,
  step
) => {
  // interpolate array of objects. Object must have {x, y} keys.
  let array = []
  const minLength = Math.min(initialArray.length, finalArray.length)
  for (let index = 0; index < minLength; index++) {
    const initialPoint = initialArray[index]
    const finalPoint = finalArray[index]
    const t = bezier(step / nsteps, 1, 1)
    const x = interpolateLinear(t, 0, 1, initialPoint.x, finalPoint.x)
    const y = interpolateLinear(t, 0, 1, initialPoint.y, finalPoint.y)
    array.push({ ...initialPoint, x, y })
  }

  return array
}

export const interpolateLinear = (x, x0, x1, y0, y1, truncate = false) => {
  const y = ((y1 - y0) / (x1 - x0)) * (x - x0) + y0

  if (truncate) {
    return Math.max(y0, Math.min(y, y1))
  }

  return y
}

const bezier = (t, P2, P3) => {
  // simplified version of cubic-bezier where x1 = 1, x2=1/3, x3=2/3, x4=1
  const P1 = 0
  const P4 = 1
  return (
    Math.pow(1 - t, 3) * P1 +
    3 * Math.pow(1 - t, 2) * t * P2 +
    3 * (1 - t) * Math.pow(t, 2) * P3 +
    Math.pow(t, 3) * P4
  )
}
